import React, { Fragment, useEffect, useState, useRef } from "react";
import cx from "classnames";

import { connect, useSelector } from "react-redux";

import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import HeaderLogo from "../AppLogo";
import UserBox from "./Components/UserBox";
import { Roles } from "../../utils/Roles";
// import HeaderRightDrawer from "./Components/HeaderRightDrawer";
import { useHistory, useLocation } from "react-router";
import GetStudentService from "../../services/SchoolIT/GetStudentService";

import HeaderDots from "./Components/HeaderDots";
import CustomLoader from "../../components/customLoader/CustomLoader";;

const Header = () => {
  let { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow, closedSmallerSidebar } = useSelector(state => state.ThemeOptions);
  const [user, setUser] = useState(useSelector(state => state.UserReducer.userObj));
  let location = useLocation();
  let history = useHistory();
  const [openSchoolOption, setOpenSchoolOption] = useState(false);
  const [schoolName, setSchoolName] = useState(JSON.parse(localStorage.getItem("user"))?.school_name);
  const [selectedSchool, setSelectedSchool] = useState();
  const [userOnRoute, setUserOnRoute] = useState(location?.pathname.split('/').pop())



  const [modifyData, setModifyData] = useState({});
  const [schoolId, setSchoolId] = useState();
  const [schoolName1, setSchoolName1] = useState(JSON.parse(localStorage.getItem("user"))?.school_name);
  // const [schoolName, setSchoolName] = useState();
  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState();
  const schoolNameRef = useRef();
  const timeoutRef = useRef();
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);
  const [openUploadSchool, setOpenUploadSchool] = useState(false);
  const [openNewSchool, setOpenNewSchool] = useState(false);
  const [openModifySchool, setOpenModifySchool] = useState(false);
  const [enableState, setEnableState] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const openSchoolOptionHandler = () => {
    setOpenSchoolOption(!openSchoolOption);
  };

  const selectSchoolOptionHandler = (value) => {
    console.log(value);
    setSchoolName1(value.name);
    setSelectedSchool(value);
    setOpenSchoolOption(false);
  };


  useEffect(() => {
    // if(user.role === "counsellor")
    if (selectedSchool) {
      console.log("selected School", selectedSchool);
      let obj = {
        ...user,
        school_id: selectedSchool?.id,
        school_name: selectedSchool?.name,
      };
      localStorage.setItem("user", JSON.stringify(obj));
      if (
        user?.role === "counselor" &&
        user?.is_super_counselor === true && userOnRoute !== "all-students"
      ) {
        console.log("1");
        history.push("/super_admin/all-students");
      } else if (
        user?.role === "counselor" &&
        user?.is_super_counselor === false && userOnRoute !== "all-students"
      ) {
        console.log("2");
        history.push("/counselor/all-students");
      } else if (userOnRoute === "all-students" && user?.role === "counselor" && user?.is_super_counselor === true) {
        console.log("3");
        window.location.reload();
      } else if (userOnRoute === "all-students" && user?.role === "counselor" && user?.is_super_counselor === false) {
        console.log("4");
        window.location.reload();
      }
    }
  }, [selectedSchool]);


  const getSchoolsList = (value) => {
    setSchoolNameLoading(true);
    let obj = {
      q: value
    }
    GetStudentService.getSchoolsList(obj)
      .then((data) => {
        console.log("data from the service", data);
        setSchoolNameList(data?.data?.school);

        setSchoolNameLoading(false);
        setShowSchoolNameContainer(true);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setSchoolNameLoading(false);
      });
  };

  const handleSchoolName = () => {
    const searchTerm = schoolNameRef.current.value.toLowerCase();
    setSchoolName1(searchTerm);

    if (!searchTerm) {
      setShowSchoolNameContainer(false);
      setSchoolNameList([]);
    } else {
      // Filter schools from user.schools_name instead of API call
      const filteredSchools = user?.schools_name?.filter(school =>
        school.name.toLowerCase().includes(searchTerm)
      ) || [];

      setSchoolNameList(filteredSchools);
      setShowSchoolNameContainer(true);
    }
  };

  // useEffect(() => {
  //   if (selectedSchool) {
  //     console.log("selected School", selectedSchool);
  //     let obj = {
  //       ...user,
  //       school_id: selectedSchool?.id,
  //       school_name: selectedSchool?.name,
  //     };
  //     localStorage.setItem("user", JSON.stringify(obj));
  //   window.location.reload();
  // }}, [selectedSchool]);


  const handleSelectedSchoolName = (item) => {
    setSchoolNameList([item]);
    setShowSchoolNameContainer(false);
    setSchoolName1(item?.name);
    // setOpenModifySchool(true);
    setEnableState(true);
    setModifyData(item);
    setSchoolId(item?.id);
    // setSchoolName('');
    // else if (selectedSchool && user.role === "school_it"){
    let obj = {
      ...user,
      school_id: item?.id,
      school_name: item?.name,
      district: item?.id,
      district_name: item?.name,
      counselor_id: item?.counselor_id,
    };
    localStorage.setItem("user", JSON.stringify(obj));
    window.location.reload();
  };

  return (
    <>
      <ReactCSSTransitionGroup
        component="div"
        // className={cx("app-header", headerBackgroundColor, {
        //   "header-shadow": enableHeaderShadow,
        // })}
        className={cx("app-header", headerBackgroundColor)}
        transitionName="HeaderAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <HeaderLogo />

        <div
          className={cx("app-header__content", {
            "header-mobile-open": enableMobileMenuSmall,
          })}
        >
          <div className="app-header-right">
            <div></div>
            {user.role == Roles.STUDENT_NAME || user.role == Roles.PARENT_NAME ? <HeaderDots /> : ""}
            {user.role === "counselor" && (
              <div
                style={{
                  //marginRight:"30%",
                  justifyContent: "center",
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                <div className="school-selector-container" style={{ display: "flex", alignItems: "center" }}>
                  {schoolName ? (
                    <>
                      <p
                        onClick={openSchoolOptionHandler}
                        className="no-margin-bottom cursor"
                        style={{ textDecoration: "underline", fontSize: "22px", marginRight: "10px" }}
                      >
                        {schoolName}
                      </p>
                      <span
                        onClick={() => {
                          setSchoolName(""); // Clear the school name
                          setSelectedSchool(null); // Clear selected school
                          setSchoolName1(""); // Clear search input
                          setOpenSchoolOption(true); // Open the container
                          // Focus on search input after a short delay
                          setTimeout(() => {
                            if (schoolNameRef.current) {
                              schoolNameRef.current.focus();
                            }
                          }, 100);
                        }}
                        style={{
                          cursor: "pointer",
                          fontSize: "16px",
                          fontWeight: "bold",
                          padding: "0 5px",
                          color: "red", // Red X
                          marginLeft: "5px"
                        }}
                      >
                        ✕
                      </span>
                    </>
                  ) : (
                    <p
                      onClick={openSchoolOptionHandler}
                      className="no-margin-bottom cursor"
                      style={{ textDecoration: "underline", fontSize: "22px" }}
                    >
                      Select School
                    </p>
                  )}
                </div>

                {openSchoolOption && (
                  <div
                    style={{
                      position: "absolute",
                      background: "white",
                      padding: "15px",
                      zIndex: "10",
                      width: "400px",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      borderRadius: "4px",
                      maxHeight: "650px",
                      overflowY: "auto"
                    }}
                  >
                    <input
                      ref={schoolNameRef}
                      className="search-student-input2"
                      type="text"
                      style={{
                        width: "100%",
                        height: "35px",
                        padding: "5px 10px",
                        marginBottom: "10px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        position: "sticky",
                        top: "0",
                        backgroundColor: "white",
                        zIndex: "1"
                      }}
                      placeholder="Search school..."
                      value={schoolName1 || ""}
                      onChange={handleSchoolName}
                      autoComplete="off"
                    />

                    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                      {showSchoolNameContainer ? (
                        schoolNameList && schoolNameList.length > 0 ? (
                          schoolNameList.map((item, index) => (
                            <div
                              key={index}
                              onClick={() => selectSchoolOptionHandler(item)}
                              className="school-option"
                              style={{
                                padding: "8px 5px",
                                cursor: "pointer",
                                borderBottom: "1px solid #eee",
                                transition: "all 0.2s ease",
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = "skyblue";
                                e.currentTarget.style.color = "white";
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = "transparent";
                                e.currentTarget.style.color = "inherit";
                              }}
                            >
                              <p className="no-margin-bottom">{item.name}</p>
                            </div>
                          ))
                        ) : (
                          <p style={{ textAlign: "center", color: "#666" }}>No schools found</p>
                        )
                      ) : (
                        user?.schools_name?.map((item, index) => (
                          <div
                            key={index}
                            onClick={() => selectSchoolOptionHandler(item)}
                            className="school-option"
                            style={{
                              padding: "8px 5px",
                              cursor: "pointer",
                              borderBottom: "1px solid #eee",
                              transition: "all 0.2s ease",
                              backgroundColor: item.name === schoolName ? "#f0f0f0" : "transparent"
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = "skyblue";
                              e.currentTarget.style.color = "white";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = item.name === schoolName ? "#f0f0f0" : "transparent";
                              e.currentTarget.style.color = "inherit";
                            }}
                          >
                            <p className="no-margin-bottom">{item.name}</p>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {user.role === "school_it" && user.school_student_progress_enable === true && (
              <div
                style={{
                  //marginRight:"30%",
                  justifyContent: "center",
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                <div>
                  <input
                    ref={schoolNameRef}
                    className="search-student-input2"
                    type="text"
                    style={{
                      width: "350px",
                      height: "35px",
                      padding: "5px 10px",
                      marginBottom: "10px",
                    }}
                    placeholder="School Name"
                    value={schoolName1}
                    onChange={handleSchoolName}
                    autoComplete="off"
                  />
                  {showSchoolNameContainer && (
                    <div style={{ position: "absolute" }}>
                      <ul
                        className="maintain-group-student-dropdown-menu"
                        style={{
                          width: "550px",
                          maxHeight: "600px",
                          overflowY: "scroll",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        {schoolNameLoading && <CustomLoader />}
                        {schoolNameList?.length > 0 &&
                          schoolNameList.map((item, index) => (
                            <li
                              onClick={() => handleSelectedSchoolName(item)}
                              key={index}
                              className="flex cursor"
                              style={{ padding: "5px 0px" }}
                            >
                              <span>{item.name}</span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>


              </div>
            )}

            <UserBox />
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}

export default Header;